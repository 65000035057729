import { Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import Loading from "../components/loading";
import Layout from "../components/layout";
import AuthRoute from "./auth_route";

const Dashboard = lazy(() => import("../pages/dashboard"));
const User = lazy(() => import("../pages/user"));
const Role = lazy(() => import("../pages/role"));
const PromotionCategory = lazy(() => import("../pages/promotion_category"));
const Promotion = lazy(() => import("../pages/promotion"));
const Gift = lazy(() => import("../pages/gift"));
const Reward = lazy(() => import("../pages/reward"));
const Import = lazy(() => import("../pages/import"));
const UploadHistory = lazy(() => import("../pages/upload_history"));
const RewardHistory = lazy(() => import("../pages/reward_history"));

const Login = lazy(() => import("../pages/login"));
const ErrorPage = lazy(() => import("../pages/error"));

export default function Router() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/">
            <Route index={true} element={
              <AuthRoute>
                <Dashboard />
              </AuthRoute>
            } />
          </Route>

          <Route path="/user">
            <Route index={true} element={
              <AuthRoute>
                <User />
              </AuthRoute>
            } />
          </Route>

          <Route path="/role">
            <Route index={true} element={
              <AuthRoute>
                <Role />
              </AuthRoute>
            } />
          </Route>

          <Route path="/promotion-category">
            <Route index={true} element={
              <AuthRoute>
                <PromotionCategory />
              </AuthRoute>
            } />
          </Route>

          <Route path="/promotion">
            <Route index={true} element={
              <AuthRoute>
                <Promotion />
              </AuthRoute>
            } />
          </Route>

          <Route path="/gift">
            <Route index={true} element={
              <AuthRoute>
                <Gift />
              </AuthRoute>
            } />
          </Route>

          <Route path="/reward">
            <Route index={true} element={
              <AuthRoute>
                <Reward />
              </AuthRoute>
            } />
          </Route>

          <Route path="/import">
            <Route index={true} element={
              <AuthRoute>
                <Import />
              </AuthRoute>
            } />
          </Route>

          <Route path="/import-history">
            <Route index={true} element={
              <AuthRoute>
                <UploadHistory />
              </AuthRoute>
            } />
          </Route>
          <Route path="/reward-history">
            <Route index={true} element={
              <AuthRoute>
                <RewardHistory />
              </AuthRoute>
            } />
          </Route>
        </Route>

        <Route path="login" element={<Login />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
}