import { NavLink, Outlet } from 'react-router-dom';
import styles from './style.module.css';
import { Accordion } from 'react-bootstrap';
import logo from '../../assets/images/logo-blue.webp'
import Menu from '../menu';

export default function Layout() {
  return (
    <div className={styles.main + ' d-flex'}>
      <div className='col-2 bg-white' style={{ minWidth: 250, maxWidth: 280 }}>
        <div className='mb-4'>
          <img className='w-100' src={logo} alt="logo" />
        </div>
        <Menu />
      </div>
      <div className='col-10 ps-3 position-relative'>
        <Outlet />
      </div>
    </div>
  )
}