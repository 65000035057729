export const USER_LOGIN = `${process.env.REACT_APP_API_URL}/auth/login`;
export const USER_VERIFY = `${process.env.REACT_APP_API_URL}/auth/verify`;

export const USERS_GET_PAGING = `${process.env.REACT_APP_API_URL}/user/getPaging`;
export const USER_CREATE = `${process.env.REACT_APP_API_URL}/user/create`;
export const USER_UPDATE = `${process.env.REACT_APP_API_URL}/user/update`;
export const USER_DELETE = `${process.env.REACT_APP_API_URL}/user/delete`;

export const ROLES_GET_ALL = `${process.env.REACT_APP_API_URL}/role/getAll`;
export const ROLES_GET_PAGING = `${process.env.REACT_APP_API_URL}/role/getPaging`;
export const ROLE_CREATE = `${process.env.REACT_APP_API_URL}/role/create`;
export const ROLE_UPDATE = `${process.env.REACT_APP_API_URL}/role/update`;
export const ROLE_DELETE = `${process.env.REACT_APP_API_URL}/role/delete`;

export const REWARDS_GET_PAGING = `${process.env.REACT_APP_API_URL}/reward/getPaging`;
export const REWARD_IMPORT_EXCEL = `${process.env.REACT_APP_API_URL}/reward/import`;
export const REWARD_CREATE = `${process.env.REACT_APP_API_URL}/reward/create`;
export const REWARD_UPDATE = `${process.env.REACT_APP_API_URL}/reward/update`;
export const REWARD_DELETE = `${process.env.REACT_APP_API_URL}/reward/delete`;

export const REWARD_HISTORIES_GET_PAGING = `${process.env.REACT_APP_API_URL}/reward-history/getPaging`;

export const PROMOTION_CATEGORIES_GET_ALL = `${process.env.REACT_APP_API_URL}/promotion-category/getAll`;
export const PROMOTION_CATEGORIES_GET_PAGING = `${process.env.REACT_APP_API_URL}/promotion-category/getPaging`;
export const PROMOTION_CATEGORY_CREATE = `${process.env.REACT_APP_API_URL}/promotion-category/create`;
export const PROMOTION_CATEGORY_UPDATE = `${process.env.REACT_APP_API_URL}/promotion-category/update`;
export const PROMOTION_CATEGORY_DELETE = `${process.env.REACT_APP_API_URL}/promotion-category/delete`;

export const PROMOTIONS_GET_ALL = `${process.env.REACT_APP_API_URL}/promotion/getAll`;
export const PROMOTIONS_GET_PAGING = `${process.env.REACT_APP_API_URL}/promotion/getPaging`;
export const PROMOTION_CREATE = `${process.env.REACT_APP_API_URL}/promotion/create`;
export const PROMOTION_UPDATE = `${process.env.REACT_APP_API_URL}/promotion/update`;
export const PROMOTION_DELETE = `${process.env.REACT_APP_API_URL}/promotion/delete`;

export const GIFTS_GET_ALL = `${process.env.REACT_APP_API_URL}/gift/getAll`;
export const GIFTS_GET_PAGING = `${process.env.REACT_APP_API_URL}/gift/getPaging`;
export const GIFT_CREATE = `${process.env.REACT_APP_API_URL}/gift/create`;
export const GIFT_UPDATE = `${process.env.REACT_APP_API_URL}/gift/update`;
export const GIFT_DELETE = `${process.env.REACT_APP_API_URL}/gift/delete`;

export const UPLOAD_HISTORY_GET_PAGING = `${process.env.REACT_APP_API_URL}/upload-history/getPaging`;

export const UPLOAD_IMAGE = `${process.env.REACT_APP_API_URL}/image/upload`;